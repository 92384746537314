<template>
  <div class="modal fade modal-alert" :id="id" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-html="content"></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn"
            data-dismiss="modal"
            @click="$emit('no')"
          >{{ secondaryBtnText }}</button>
          <button type="button" class="btn btn-primary" @click="$emit('yes')">{{ primaryBtnText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "alert-box",
  props: {
    // show: Boolean,
    title: String,
    content: String,
    primaryBtnText: {
      type: String,
      default: "Exit"
    },
    secondaryBtnText: {
      type: String, 
      default: "Cancel"
    }
  },
  data: function() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/modal";
</style>
