<template>
  <div class="admin-careers-new">
    <Form @submit="onSubmit" ref="newPosting"/>
    <Alert 
      id="alert-career-leave"
      title="Confirmation"
      content="Are you sure you want to exit without saving?"
    />
  </div>
</template>

<script>
import Form from "@/components/admin/CareerForm.vue";
import Alert from "@/components/admin/AlertBox.vue"
import axios from "axios";

export default {
  name: "admin-careers-new",
  components: { Form, Alert },
  methods: {
    onSubmit(form) {
      console.log("submitting form", form);
      //TODO: validation
      this.postPosting(form);
    },
    async postPosting(form) {
      try {
        let res = await axios({
          url: "http://testdeployd.aezypay.com/postings",
          method: "post",
          data: form,
          withCredentials: true,
        });
        //TODO: success msg
        this.$refs.newPosting.resetForm();
        this.$router.push({ path: "/admin/careers" });
      } catch (error) {
        // TODO: failure msg
        console.log("error", error);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    // ...
    if (true) {
      if (confirm("Are you sure")) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>

<style lang="scss" scoped>
.admin-careers-new {
  background: #f9f9f9;
  padding: 50px;
  height: 100%;
}
</style>
